import axios from 'axios';
import { toast } from 'react-toastify';

axios.interceptors.response.use(null, (error) => {
  const ex = error.response;
  const clientError = ex && ex.status >= 400 && ex.status < 500;

  if (clientError) {
    if (window.location.pathname !== '/login' && ex.status === 401)
      window.location = '/logout';
    return Promise.reject(error);
  }

  toast('Unexpected error occured.');
  console.log(error);
});

export const endUrl = 'https://iksen.in/nwida/public/api/';

const api = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default api;
