import { Component } from 'react';
import Joi from 'joi';

export default class Form extends Component {
  state = {
    data: {},
    error: {},
  };

  validateInput = (name, value) => {
    const schema = Joi.object({ [name]: this.schemaOpt[name] });
    const { error } = schema.validate({ [name]: value });
    return error ? error.details[0].message : null;
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...this.state.data };
    data[name] = value;

    const error = { ...this.state.error };
    const errorMsg = this.validateInput(name, value);
    if (errorMsg) error[name] = errorMsg;
    else delete error[name];

    this.setState({ data, error });
  };

  validate = () => {
    const schema = Joi.object(this.schemaOpt).options({ stripUnknown: true });
    const { error } = schema.validate(this.state.data, { abortEarly: false });
    if (!error) return null;

    let errors = {};
    error.details.map((item) => (errors[item.path[0]] = item.message));
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const error = this.validate();
    if (error) return this.setState({ error });

    this.doSubmit();
  };
}
