function Input({ name, type, label, onChange, error, required, ...rest }) {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <input
        type={type ? type : 'text'}
        className={error ? 'form-control is-invalid' : 'form-control'}
        id={name}
        name={name}
        onChange={onChange}
        {...rest}
      />
      {error && <div className="form-text text-danger">{error}</div>}
    </div>
  );
}

export default Input;
