import http, { endUrl } from './httpService';
import { getToken } from './authService';

const headers = () => ({
  headers: {
    Authorization: 'Bearer ' + getToken(),
  },
});

export function getUsers() {
  return http.post(endUrl + 'get-users-list', {}, headers());
}

export function addUser(data) {
  return http.post(endUrl + 'add-user', data, headers());
}

export function deleteUser(id) {
  return http.post(endUrl + 'delete-user', { user_id: id }, headers());
}

export function userStatus(id) {
  const url = endUrl + 'activate-deactivate-user';

  return http.post(url, { user_id: id }, headers());
}

export default {
  getUsers,
  addUser,
  deleteUser,
  userStatus,
};
