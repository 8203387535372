import Menus from './Menus';

function SidebarAdmin() {
  const menus = [
    {
      name: 'Dashboard',
      link: 'dashboard',
      icon: <i className="bx bx-home-circle" />,
    },
    {
      name: 'Users',
      link: 'users',
      icon: <i className="bx bx-user" />,
      sub: [
        {
          name: 'Add',
          link: 'users/add',
        },
        {
          name: 'List',
          link: 'users/list',
        },
      ],
    },
    {
      name: 'Display Data',
      link: 'upload',
      icon: <i className="bx bx-upload" />,
    },
  ];

  return <Menus items={menus} />;
}

export default SidebarAdmin;
