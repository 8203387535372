import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/userService';
import Loader from './common/Loader';

function List() {
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _getUsers();
  }, []);

  async function _getUsers() {
    setLoading(true);
    const { data } = await api.getUsers();
    setUsers(data.results.user_lists);
    setLoading(false);
  }

  function columns(arr) {
    return Object.keys(arr[0]);
  }

  function modClass(col) {
    let fix = col === 0 ? 'danger' : 'success';

    return `pointer badge bg-${fix} rounded-pill`;
  }

  async function handleDelete(id) {
    try {
      setUsers((prev) => prev.filter((item) => item.user_id !== id));
      await api.deleteUser(id);
    } catch (ex) {
      if (ex.response?.status === 400) {
        setUsers(users);

        toast(ex.response.data.msg);
      }
    }
  }

  async function handleStatus(user) {
    const data = [...users];
    const index = data.indexOf(user);
    data[index] = { ...user };
    data[index].status = user.status ? 0 : 1;
    setUsers(data);

    await api.userStatus(user.user_id);
  }

  return (
    <div className="container-fluid px-4">
      {loading ? (
        <Loader />
      ) : users ? (
        <div className="table-responsive full-view">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th>Action</th>
                {columns(users).map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((row, index) => (
                <tr key={index}>
                  <td>
                    <div className="row">
                      <div className="col">
                        <i
                          className={
                            'bx pointer fs-2 text-' +
                            (row.status
                              ? 'success bxs-toggle-right'
                              : 'secondary bx-toggle-left')
                          }
                          onClick={() => handleStatus(row)}
                        ></i>
                      </div>
                      <div className="col">
                        <i
                          className="bx bx-lock pointer fs-4 text-danger"
                          onClick={() => handleDelete(row.user_id)}
                        />
                      </div>
                    </div>
                  </td>
                  {columns(users).map((col, idx) => (
                    <td key={idx}>
                      {col === 'status' ? (
                        <span className={modClass(row[col])}>
                          {row[col] === 0 ? 'Inactive' : 'Active'}
                        </span>
                      ) : (
                        row[col]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h4>No data found</h4>
      )}
    </div>
  );
}

export default List;
