function Select(props) {
  const {
    name,
    placeholder,
    options,
    label,
    onChange,
    error,
    required,
    ...rest
  } = props;

  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </label>
      <select
        name={name}
        id={name}
        className={error ? 'form-select is-invalid' : 'form-select'}
        onChange={onChange}
        {...rest}
      >
        {placeholder && <option value="">{placeholder}</option>}
        {options.data.map((opt) => (
          <option key={opt[options.value]} value={opt[options.value]}>
            {opt[options.label]}
          </option>
        ))}
      </select>
      {error && <div className="form-text text-danger">{error}</div>}
    </div>
  );
}

export default Select;
