import { Link } from 'react-router-dom';
import Logo from '../../asset/logo.png';
import Profile from '../../asset/demo.jpg';
import 'bootstrap/js/dist/dropdown';

function Navbar({ user }) {
  const trigger = () => {
    const app = document.querySelector('.App');
    if (app.classList.contains('active-sidebar'))
      app.classList.remove('active-sidebar');
    else app.classList.add('active-sidebar');
  };

  return (
    <header id="header">
      <div className="logo-group">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <button className="btn trigger" onClick={trigger}>
          <i className="bx bx-menu" />
        </button>
      </div>
      <div className="dropdown profile me-3">
        <button
          className="btn dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src={Profile} alt="" /> <span>{user?.name}</span>
        </button>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item" href="/">
              Action
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="/">
              Another action
            </a>
          </li>
          <li>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-18 align-middle me-1 text-danger" />
              Logout
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Navbar;
