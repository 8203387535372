import React from 'react';
import { Modal, Button, FormCheck } from 'react-bootstrap';
import Joi from 'joi';
import { toast } from 'react-toastify';
import Input from './common/Input';
import From from './common/Form';
import api from '../services/dataService';

class UpdateData extends From {
  state = {
    show: this.props.show,
    data: { ...this.props.user },
    error: {},
  };

  schemaOpt = {
    company_name: Joi.string().allow(''),
    first_name: Joi.string().allow(''),
    last_name: Joi.string().allow(''),
    address_1: Joi.string().allow(''),
    address_2: Joi.string().allow(''),
    city: Joi.string().required(),
    state: Joi.string().required(),
    postal_code: Joi.number(),
    phone_no: Joi.string().allow(''),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .allow(''),
  };

  handleClose = () => {
    this.setState({ show: false });
    setTimeout(() => {
      this.props.setShow(false);
    }, 300);
  };

  doSubmit = async () => {
    try {
      const { data } = await api.updateData(this.state.data);
      toast(data.msg);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (ex) {
      if (ex.response) {
        toast(ex.response.data.msg);
      }
    }
  };

  renderInput = (name, label) => (
    <Input
      name={name}
      label={label}
      onChange={this.handleChange}
      value={this.state.data[name]}
      error={this.state.error[name]}
    />
  );

  toggleState = () => {
    const data = { ...this.state.data };
    data.active_member = data.active_member === 'Yes' ? 'No' : 'Yes';
    this.setState({ data });
  };

  render() {
    return (
      <Modal
        show={this.state.show}
        backdrop="static"
        size="lg"
        onHide={this.handleClose}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                {this.renderInput('first_name', 'First Name')}
              </div>
              <div className="col-md-6">
                {this.renderInput('last_name', 'Last Name')}
              </div>
              <div className="col-md-6">
                <label className="form-label d-block">Active Member</label>
                <FormCheck
                  type="switch"
                  id="custom-switch"
                  checked={this.state.data.active_member === 'Yes'}
                  onChange={this.toggleState}
                />
              </div>
              <div className="col-md-6">
                {this.renderInput('company_name', 'Company Name')}
              </div>
              <div className="col-md-6">
                {this.renderInput('address_1', 'Address 1')}
              </div>
              <div className="col-md-6">
                {this.renderInput('address_2', 'Address 2')}
              </div>
              <div className="col-md-6">{this.renderInput('city', 'City')}</div>
              <div className="col-md-6">
                {this.renderInput('state', 'State')}
              </div>
              <div className="col-md-6">
                {this.renderInput('postal_code', 'Postal Code')}
              </div>
              <div className="col-md-6">
                {this.renderInput('phone_no', 'Phone')}
              </div>
              <div className="col-md-6">
                {this.renderInput('email', 'Email Id')}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default UpdateData;
