import React, { useEffect, useState } from 'react';
import Loader from './common/Loader';
import UpdateData from './UpdateData';
import api from '../services/dataService';

function DataUpload({ ifAdmin }) {
  const [data, setData] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedData, setSelectedData] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    _getdata();
  }, []);

  const _getdata = async () => {
    setLoading(true);
    const { data } = await api.getData();

    setData(data.results.info_lists);
    setDataKey(Object.keys(data.results.info_lists[0]));
    setLoading(false);
  };

  async function uploadCsv() {
    try {
      const formData = new FormData();
      formData.append('csv_file', doc);

      await api.uploadCsv(formData);
      _getdata();
    } catch (error) {
      alert(error);
    }
  }

  function handleUpdate(data) {
    setSelectedData(data);
    setShow(true);
  }

  const filteredData =
    search !== ''
      ? data.filter((row) => {
          const iftrue = Object.keys(row).map((column) =>
            row[column].toString().toLowerCase().includes(search.toLowerCase())
          );
          return iftrue.includes(true);
        })
      : data;

  return (
    <div className="container-fluid px-4">
      <div className="row align-items-end justify-content-end justify-content-lg-between">
        {ifAdmin && (
          <div className="col-auto mb-3">
            <label htmlFor="csvInput">Enter CSV File</label>
            <div className="d-flex">
              <input
                className="form-control"
                id="csvInput"
                name="csv_file"
                type="File"
                onChange={(e) => setDoc(e.target.files[0])}
              />
              <button
                className="btn btn-success text-white"
                onClick={uploadCsv}
              >
                Parse
              </button>
            </div>
          </div>
        )}
        <div className="col-auto">
          <div className="search-input mb-3">
            <i className="bx bx-search" />
            <input
              type="text"
              className="form-control"
              placeholder="Serch..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="table-responsive full-view">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                {dataKey.map((col, idx) =>
                  col === 'info_id' ? (
                    ifAdmin ? (
                      <th key={idx}>Action</th>
                    ) : null
                  ) : (
                    <th key={idx}>{col}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, index) => (
                <tr key={index}>
                  {dataKey.map((col, idx) =>
                    col === 'info_id' ? (
                      ifAdmin ? (
                        <td key={idx}>
                          <i
                            className="bx bxs-edit pointer fs-4 text-primary"
                            onClick={() => handleUpdate(row)}
                          />
                        </td>
                      ) : null
                    ) : (
                      <td key={idx}>{row[col]}</td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {show && <UpdateData show={show} user={selectedData} setShow={setShow} />}
    </div>
  );
}

export default DataUpload;
