import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../../services/authService';

const ProtectedRoute = ({ path, component: Component, ...rest }) => {
  const user = getToken();

  return (
    <Route
      path={path}
      render={(props) =>
        user ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { form: props.location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
