import { useEffect } from 'react';
import { logout } from '../services/authService';

function Logout() {
  useEffect(() => {
    logout();

    window.location = '/login';
  }, []);

  return null;
}

export default Logout;
