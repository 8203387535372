import { useLocation } from 'react-router-dom';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import SidebarAdmin from './SidebarAdmin';
import Footer from './Footer';

const Layout = ({ user, ifAdmin, children }) => {
  const location = useLocation();

  let fullpage = false;
  const fullpagePaths = ['login', 'not-found', 'forgot-password'];

  fullpagePaths.forEach((path) => {
    if (location.pathname === '/' + path) fullpage = true;
  });

  return (
    <>
      {!fullpage && <Navbar user={user} />}
      {!fullpage && (ifAdmin ? <SidebarAdmin /> : <Sidebar />)}
      <div id="view" className={fullpage ? 'fullpage' : ''}>
        <main id="content">{children}</main>
        {!fullpage && <Footer />}
      </div>
    </>
  );
};

export default Layout;
