import './loading.css';

function index() {
  return (
    <div className="d-flex" style={{ height: 300 }}>
      <div className="lds-ellipsis m-auto">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default index;
