import { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getUserDetails } from './services/authService';
import Layout from './components/includes/layout';
import SRoute from './components/common/ProtectedRoute';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';
import AddUser from './components/AddUser';
import List from './components/List';
import UploadData from './components/UploadData';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = getUserDetails();
    setUser(user);
  }, []);

  const admin = user?.user_type_id === 1;

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={3000} theme="dark" />
      <Layout user={user} ifAdmin={admin}>
        <Switch>
          <SRoute path="/dashboard" component={Dashboard} user={user} />
          <SRoute path="/users/add" component={AddUser} />
          <SRoute path="/users/list" component={List} />

          <SRoute path="/upload" component={UploadData} ifAdmin={admin} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />

          <Redirect exact from="/" to="/upload" />
          <Redirect to="/not-found" />
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
