import React from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi';
import { toast, Flip } from 'react-toastify';
import Form from './common/Form';
import Input from './common/Input';
import api, { endUrl } from '../services/httpService';

class ForgotPassword extends Form {
  state = {
    data: {
      email: '',
    },
    error: {},
  };

  schemaOpt = {
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .required(),
  };

  doSubmit = async () => {
    const msg = toast.loading('Please wait...', { position: 'top-center' });
    try {
      const { data } = await api.post(
        `${endUrl}forget-password`,
        this.state.data
      );
      toast.update(msg, {
        render: data.msg,
        type: 'success',
        autoClose: 3000,
        transition: Flip,
        isLoading: false,
      });
    } catch (error) {
      if (error.response?.status === 400)
        toast.update(msg, {
          render: error.response.data.msg,
          type: 'error',
          autoClose: 3000,
          transition: Flip,
          isLoading: false,
        });
    }
  };

  render() {
    return (
      <div className="container">
        <div className="row vh-100 justify-content-center align-items-center">
          <div className="col">
            <div className="form-wrapper">
              <p>
                <Link to="/" className="fw-bold text-decoration-none">
                  &#60; back to home
                </Link>
              </p>
              <div className="card shadow mb-4">
                <div className="bg-primary bg-soft">
                  <div className="text-primary p-4">
                    <h5 className="text-primary mb-0">Forgot Password?</h5>
                  </div>
                </div>
                <div className="px-4 card-body">
                  <div className="py-2">
                    <form
                      onSubmit={this.handleSubmit}
                      className="form-horizontal"
                    >
                      <Input
                        name="email"
                        label="Enter Email"
                        type="email"
                        onChange={this.handleChange}
                        value={this.state.data.email}
                        error={this.state.error.email}
                        placeholder="Email"
                      />
                      <button type="submit" className="btn btn-primary w-100">
                        Send Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <p className="text-center">
                &copy; 2022 Crafted with{' '}
                <i className="bx bxs-heart text-danger align-middle" /> by Iksen
                India Pvt Ltd
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
