import { Redirect } from 'react-router-dom';
import FormBG from '../../asset/logBG.png';
import Logo from '../../asset/logo-sm.png';
import LogForm from './LogForm';
import './Login.css';

function Login({ location }) {
  const user = localStorage.getItem('token');

  return user ? (
    <Redirect to="/" />
  ) : (
    <div className="container">
      <div className="row vh-100 justify-content-center align-items-center">
        <div className="col">
          <div className="form-wrapper">
            <div className="card shadow mb-4">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Welcome</h5>
                      <p>Sign in to continue.</p>
                    </div>
                  </div>
                  <div className="col-5 align-self-end col">
                    <img src={FormBG} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="pt-0 px-4 card-body">
                <div>
                  <div className="avatar-md profile-user-wid mb-4">
                    <a className="auth-logo-light" href="/">
                      <span className="avatar-title bg-light">
                        <img src={Logo} alt="" height={34} />
                      </span>
                    </a>
                  </div>
                </div>
                <div className="py-2">
                  <LogForm from={location.state?.form} />
                </div>
              </div>
            </div>
            <p className="text-center">
              &copy; 2022 Crafted with{' '}
              <i className="bx bxs-heart text-danger align-middle" /> by Iksen
              India Pvt Ltd
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
