import http, { endUrl } from './httpService';
import { getToken } from './authService';

const headers = () => ({
  headers: {
    Authorization: 'Bearer ' + getToken(),
  },
});

export function getData() {
  return http.post(endUrl + 'get-information-list', {}, headers());
}

export function uploadCsv(data) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + getToken(),
  };
  return http.post(endUrl + 'upload-csv', data, { headers });
}

export function updateData(data) {
  return http.post(endUrl + 'update-information', data, headers());
}

export default { getData, uploadCsv, updateData };
