import jwtDecode from 'jwt-decode';
import api, { endUrl } from './httpService';

const token = 'token';

export async function login(email, password) {
  const { data } = await api.post(endUrl + 'login', { email, password });
  localStorage.setItem(token, data);
}

export function logout() {
  localStorage.removeItem(token);
}

export const getToken = () => localStorage.getItem(token);

export function getUserDetails() {
  try {
    return jwtDecode(getToken());
  } catch (error) {
    return null;
  }
}
