import React from 'react';
import { Link } from 'react-router-dom';
import Joi from 'joi';
import Form from '../common/Form';
import Input from '../common/Input';
import { login } from '../../services/authService';

export default class Login extends Form {
  state = {
    data: {
      email: '',
      password: '',
    },
    error: {},
  };

  schemaOpt = {
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .required(),
    password: Joi.string().required(),
  };

  doSubmit = async () => {
    const { email, password } = this.state.data;
    try {
      await login(email, password);

      const path = this.props.from;
      window.location = path || '/';
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const error = { ...this.state.error };
        error.email = 'Not a valid email or password';
        this.setState({ error });
      }
    }
  };

  renderInput = (name) => (
    <Input
      name={name}
      label={name.toUpperCase()}
      type={name}
      onChange={this.handleChange}
      value={this.state.data[name]}
      error={this.state.error[name]}
      placeholder={'Enter ' + name.toUpperCase()}
    />
  );

  render() {
    return (
      <form onSubmit={this.handleSubmit} className="form-horizontal">
        {this.renderInput('email')}
        {this.renderInput('password')}
        <button type="submit" className="btn btn-primary w-100">
          Log In
        </button>
        <div className="mt-2 text-center">
          <Link className="text-muted" to="/forgot-password">
            <i className="mdi mdi-lock me-1" />
            Forgot your password?
          </Link>
        </div>
      </form>
    );
  }
}
