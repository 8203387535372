import React from 'react';
import Joi from 'joi';
import Form from './common/Form';
import Input from './common/Input';
import Select from './common/Select';
import { toast, Flip } from 'react-toastify';
import { addUser } from '../services/userService';

export default class AddUser extends Form {
  state = {
    data: {
      name: '',
      shortuser: '',
      email: '',
      roll: '',
    },
    error: {},
    users: [],
  };

  schemaOpt = {
    name: Joi.string().required(),
    shortuser: Joi.string().required(),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: false } })
      .required(),
    roll: Joi.string().required(),
  };

  doSubmit = async () => {
    const submit = toast.loading('Please wait...');

    try {
      await addUser(this.state.data);
      toast.update(submit, {
        render: 'User added successfully.',
        type: 'success',
        autoClose: 3000,
        transition: Flip,
        isLoading: false,
      });
    } catch (ex) {
      if (ex.response?.status === 400)
        toast.update(submit, {
          render: ex.response.data.msg,
          type: 'error',
          autoClose: 3000,
          transition: Flip,
          isLoading: false,
        });
    }
  };

  renderInput = (name, label) => (
    <Input
      name={name}
      label={label}
      onChange={this.handleChange}
      error={this.state.error[name]}
      placeholder={'Enter ' + label}
      required
    />
  );
  renderSelect = (name, label, options) => (
    <Select
      name={name}
      label={label}
      onChange={this.handleChange}
      error={this.state.error[name]}
      placeholder="choose..."
      options={{
        data: [
          { id: 'opt1', name: 'Option 1' },
          { id: 'opt2', name: 'Option 2' },
          { id: 'opt3', name: 'Option 3' },
        ],
        value: 'id',
        label: 'name',
      }}
      required
    />
  );

  render() {
    return (
      <div className="container">
        <h4 className="fw-normal my-4">Aaa Users</h4>
        <div className="card shadow">
          <div className="card-body pt-4">
            <form onSubmit={this.handleSubmit} className="form-horizontal">
              <div className="row">
                <div className="col-md-6">
                  {this.renderInput('name', 'User Name')}
                </div>
                <div className="col-md-6">
                  {this.renderInput('shortuser', 'User Name short')}
                </div>
                <div className="col-md-6">
                  {this.renderInput('email', 'User Email ID')}
                </div>
                <div className="col-md-6">
                  {this.renderSelect('roll', 'User Roll')}
                </div>
              </div>
              <p className="text-end">
                <button type="submit" className="btn btn-primary px-4">
                  Add User
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
