import { NavLink, Link, useLocation } from 'react-router-dom';
import 'bootstrap/js/dist/collapse';

function Menus({ items }) {
  const location = useLocation();

  items.forEach((obj) => {
    if (obj.sub) {
      if (location.pathname.startsWith('/' + obj.link)) {
        obj.active = true;
      } else {
        obj.active = false;
      }
    }
  });

  return (
    <aside id="sidebar">
      <h6 className="menu-title m-4">Menu</h6>
      <ul className="accordion side-menu" id="sideMenu">
        {items.map((list, inx) => (
          <li key={inx} className="item">
            <NavLink
              to={`/${list.link}`}
              className={
                list.sub
                  ? 'accordion-button' + (list.active ? '' : ' collapsed')
                  : ''
              }
              data-bs-toggle={list.sub ? 'collapse' : ''}
              data-bs-target={list.sub ? `#item${inx}` : ''}
            >
              {list.icon} <span className="text">{list.name}</span>
            </NavLink>
            {list.sub && (
              <ul
                id={`item${inx}`}
                className={
                  'accordion-collapse collapse' + (list.active ? ' show' : '')
                }
                data-bs-parent="#sideMenu"
              >
                {list.sub.map((sub, sinx) => (
                  <li key={sinx}>
                    <NavLink to={`/${sub.link}`}>{sub.name}</NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <hr />
        <li className="item">
          <Link to="/logout" className="item">
            <i className="bx bx-power-off text-danger" />
            <span className="text">Logout</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
}

export default Menus;
