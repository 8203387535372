import { useEffect } from 'react';
import { useState } from 'react';
import dp from '../asset/demo.jpg';
import { getUsers } from '../services/userService';
import { getData } from '../services/dataService';

function Dashboard({ user }) {
  const [users, setUsers] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    _getUsers();
    _getdata();
  }, []);

  async function _getUsers() {
    const { data } = await getUsers();
    setUsers(data.results.user_lists);
  }

  const _getdata = async () => {
    const { data } = await getData();
    setData(data.results.info_lists);
  };

  return (
    <div className="container-fluid">
      <h4>Dashboard</h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="me-3">
                  <img
                    src={dp}
                    alt="Avatar"
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="flex-grow-1 align-self-center">
                  <div className="text-muted">
                    <p className="mb-0">Welcome</p>
                    <p className="fs-5 mb-1">{user?.name}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="align-self-center col-lg-6">
              <div className="text-lg-center mt-4 mt-lg-0">
                <div className="row">
                  <div className="col-6">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        <b>Total Users</b>
                      </p>
                      <h5 className="mb-0">
                        {users ? (
                          users.length
                        ) : (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="col-6">
                    <div>
                      <p className="text-muted text-truncate mb-2">
                        <b>Total Members</b>
                      </p>
                      <h5 className="mb-0">
                        {data ? (
                          data.length
                        ) : (
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
