import Menus from './Menus';

function Sidebar() {
  const menus = [
    {
      name: 'Display Data',
      link: 'upload',
      icon: <i className="bx bx-upload" />,
    },
  ];

  return <Menus items={menus} />;
}

export default Sidebar;
